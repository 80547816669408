







































import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import GoBackButton from "../../../components/PageTitleButtons/GoBackButton.vue";
import NewUsersSafeInviteLinksModuleBuilder from "./NewUsersSafeInviteLinksModuleBuilder";
import { InputSetups } from "@/mixins/input-setups";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import BlackWhiteListInput from "piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    PageTitle,
    GoBackButton,
    BlackWhiteListInput
  },
})
export default class NewUsersSafeInviteLinksModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: NewUsersSafeInviteLinksModuleBuilder
}

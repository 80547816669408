var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-users-safe-invite-links-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('a-card',[_c('black-white-list-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {

          'model': _vm.module.model.params,
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'key': 'links',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }